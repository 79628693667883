<template>
  <ValidationProvider
    tag="div"
    class="question single-answer column is-half"
    :rules="validationRules"
    v-slot="{ errors }"
  >
    <div class="question-title">
      <div class="question-text">
        <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      </div>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field v-for="locale in survey.supported_languages" :key="locale">
          <b-radio-button
            @keydown.native="keyHandler"
            :value="value[`${question.qid}`]"
            @input="onInput"
            :native-value="locale"
            :disabled="loading"
            size="is-medium"
          >
            <div class="is-flex is-vcentered" style="min-width: 150px;">
              <!-- <div class="icon is-medium mr-5">
                <img :src="`/flags/${getCountryCode(locale)}.svg`" alt="" />
              </div> -->
              <div>{{ getLanguageNativeName(locale) }}</div>
            </div>
          </b-radio-button>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import LocaleCode from "locale-code";
import { SurveyMappingMixin } from "@/mixins/survey-mapping";
import { LOCALE_MAP, CUSTOM_LANGUAGE_NAMES } from '@/constants/locale';
import { setLang } from '@/utils/lang';
import { setRtl } from '@/utils/survey';

const getCountryCode = LocaleCode.getCountryCode;
const getLanguageNativeName = LocaleCode.getLanguageNativeName;


export default {
  name: "SelectLanguage",
  mixins: [SurveyMappingMixin, QuestionMixin],
  methods: {
    getCountryCode(locale) {
      if (!LOCALE_MAP[locale]) return locale;
      return getCountryCode(LOCALE_MAP[locale]).toLowerCase();
    },
    getLanguageNativeName(locale) {
      if (!LOCALE_MAP[locale]) return locale;
      return CUSTOM_LANGUAGE_NAMES[LOCALE_MAP[locale]] ?? getLanguageNativeName(LOCALE_MAP[locale]);
    },
    async keyHandler(e){
      if(e.key === 'Enter'){
        await this.$emit("is-enter");
      }
    },
    async onInput(locale) {
      await this.$emit("input", { [`${this.question.qid}`]: locale });
      await this.$emit("move-next");
      if (locale !== this.$route.query?.lang) {
        this.$router.replace({ query: { ...this.$route.query, lang: locale } });
      }
      this.$i18n.locale = locale;
      setLang(locale);
      if (['ar', 'fa', 'ur'].includes(locale)) setRtl(true);
      else setRtl(false);
    },
  },
};
</script>
